import request from "../utils/request";

//登录
export function login(data){
    return request({
        url:'/api/v1/wechat/qy/login',
        method:'POST',
        data
    })
}

//获取用户信息
export function getEwxUserInfo(data){
    return request({
        url:'/api/v1/wechat/qy/getEwxUserInfo',
        method:'POST',
        data
    })
}

//绑定企微账户
export function bind(data){
    return request({
        url:'/api/v1/wechat/qy/bind',
        method:'POST',
        data
    })
}

//解绑企微账户
export function unbind(data){
    return request({
        url:'/api/v1/wechat/qy/unbind',
        method:'POST',
        data
    })
}

//获取asstoken
export function getAsstoken(data){
  return request({
   url:'/api/v1/wechat/getAsstoken',
   method:'POST',
   data
  })
}
//获取Ticket
export function getTicket(data){
  return request({
   url:'/api/v1/wechat/qy/getTicket',
   method:'POST',
   data
  })
}
//获取JsapiTicket
export function getJsapiTicket(data){
   return request({
    url:'/api/v1/wechat/qy/getJsapiTicket',
    method:'POST',
    data
   })
}
//获取报名记录
export function getAppList(data){
  return request({
   url:'/api/v1/wechat/qy/getAppList',
   method:'POST',
   data
  })
}
//获取打卡记录
 export function getClockonList(data){
  return request({
   url:'/api/v1/wechat/qy/getClockonList',
   method:'POST',
   data
  })
}
//获取企业员工是否绑定内部账号
export function getOusbandStatus(data){
  return request({
   url:'/api/v1/wechat/qy/getOusbandStatus',
   method:'POST',
   data
  })
}
//绑定企业微信user_id
export function setOusband(data){
  return request({
   url:'/api/v1/wechat/qy/setOusband',
   method:'POST',
   data
  })
}
//获取客户的信息
export function getKehuUserId(data){
  return request({
   url:'/api/v1/wechat/qy/getKehuUserId',
   method:'POST',
   data
  })
}
//根据code获取个人信息
export function getMyUserId(data){
  return request({
      url: `/api/v1/wechat/qy/getMyUserId?code=${data.code}`,
      method: 'GET',
  })
}
//获取企业微信APPID
export function getQyConfigStr(data){
  return request({
   url:'/api/v1/wechat/qy/getQyConfigStr',
   method:'POST',
   data
  })
}
//获取订单列表
export function getOrderList(data){
    return request({
        url:'/api/v1/wechat/qy/getOrderList',
        method:'POST',
        data
    })
}
//获取订单key
export function getStorePositionKey(data){
    return request({
        url:'/api/v1/wechat/qy/getStorePositionKey',
        method:'POST',
        data
    })
}
//获取我的会员数据
export function getKehuDetail(data){
    return request({
        url:'/api/v1/wechat/qy/getKehuDetail',
        method:'POST',
        data
    })
}
//回访
export function setVisit(data){
    return request({
        url:'/api/v1/wechat/qy/setVisit',
        method:'POST',
        data
    })
}
//获取月份
export function getYearMonth(data){
    return request({
        url:'/api/v1/wechat/qy/getYearMonth',
        method:'POST',
        data
    })
}
// 报名详情
export function getApplets(data){
    return request({
        url:'/api/v1/wechat/qy/applets_detail',
        method:'POST',
        data
    })
}
// 聊天列表
export function getChatList(data){
    return request({
        url:'/api/v1/wechat/qy/work_wechat_chat/list',
        method:'POST',
        data
    })
}
